/* eslint-disable max-lines */
import CacheControlHeaderValues from './cache-control-header-values';
import Headers from './headers';
import { Features } from './features';

/**
 * @constant {{
 * TWO_DIGIT: String,
 * NUMERIC: String
 * }}
 */
const IntlFormatOptions = Object.freeze({
	TWO_DIGIT: '2-digit',
	NUMERIC: 'numeric'
});

/**
 * @constant {{
 * SUCCESS: String,
 * WARNING: String,
 * ERROR: String
 * }}
 */
const AlertLevels = Object.freeze({
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error'
});

/**
 * @constant {{
 * PERSON: String,
 * CONTACT: String,
 * ORGANIZATION: String
 * }}
 */
const EntityTypes = Object.freeze({
	PERSON: 'person',
	CONTACT: 'contact',
	ORGANIZATION: 'organization'
});

/**
 * Enum for subscription statuses.
 * @constant {{
 * IN_TRIAL: String
 * NON_RENEWING: String
 * }}
 */
const SubscriptionStatuses = Object.freeze({
	ACTIVE: 'active',
	NON_RENEWING: 'non_renewing'
});

/**
 * Enum for campaign statuses.
 * @constant {{
 * ACTIVE: String
 * PAUSED: String
 * DRAFT: String
 * }}
 */
const CampaignStatuses = Object.freeze({
	ACTIVE: 'active',
	PAUSED: 'paused',
	DRAFT: 'draft'
});

/**
 * Enum for connection statuses.
 * @constant {{
 * ACTIVE: String
 * PAUSED: String
 * DRAFT: String
 * }}
 */
const ConnectionStatuses = Object.freeze({
	ACTIVE: 'active',
	PAUSED: 'paused',
	DRAFT: 'draft'
});

/**
 * Enum for campaign statuses.
 * @constant {{
 * EMAIL_EVENT: String
 * }}
 */
const SegmentConditionTypes = Object.freeze({
	EMAIL_EVENT: 'emailEvent'
});

/**
 * @constant {{
 * TEXT: String
 * }}
 */
const ContactSources = Object.freeze({
	OUTFUNNEL: 'outfunnel',
	COPPER: 'copper',
	PIPEDRIVE: 'pipedrive',
	HUBSPOT: 'hubspot',
	AIRTABLE: 'airtable',
	SALESFORCE: 'salesforce',
	MAILCHIMP: 'mailchimp',
	KLAVIYO: 'klaviyo',
	MONDAY: 'monday',
	GOOGLE_SHEETS: 'google-sheets',
	MAILERLITE: 'mailerlite'
});

/**
 * @constant {{
 * CRM: String,
 * COPPER: String,
 * PIPEDRIVE: String,
 * HUBSPOT: String,
 * ZENDESK: String,
 * AIRTABLE: String,
 * SALESFORCE: String,
 * MAILCHIMP: String
 * }}
 */
const ContactSourceNames = Object.freeze({
	CRM: 'CRM',
	PIPEDRIVE: 'Pipedrive',
	COPPER: 'Copper',
	HUBSPOT: 'HubSpot',
	ZENDESK: 'Zendesk Sell',
	AIRTABLE: 'Airtable',
	SALESFORCE: 'Salesforce',
	MAILCHIMP: 'Mailchimp'
});

/**
 * @constant {{
 * PIPEDRIVE: String,
 * COPPER: String,
 * HUBSPOT: String,
 */
const CrmSources = Object.freeze([
	ContactSources.PIPEDRIVE,
	ContactSources.COPPER,
	ContactSources.HUBSPOT
]);

/**
 * @constant {{
 * pipedrive: String,
 * copper: String,
 * hubspot: String,
 * airtable: String
 * }}
 */
const ContactSourceSegmentNames = Object.freeze({
	[ContactSources.PIPEDRIVE]: 'person filter',
	[ContactSources.COPPER]: 'segment (tag, contact type or lead status)',
	[ContactSources.HUBSPOT]: 'list',
	[ContactSources.AIRTABLE]: 'view',
	[ContactSources.MAILCHIMP]: 'tag'
});

/**
 * @constant {{
 * TEXT: String
 * }}
 */
const EmailEngagementSources = Object.freeze({
	MAILCHIMP: 'mailchimp',
	ACTIVECAMPAIGN: 'activecampaign',
	SENDINBLUE: 'sendinblue',
	HUBSPOT_MARKETING: 'hubspot-marketing',
	KLAVIYO: 'klaviyo',
	MAILERLITE: 'mailerlite',
	CONSTANT_CONTACT: 'constantcontact',
	GETRESPONSE: 'getresponse',
	SENDGRID: 'sendgrid',
	GOOGLE_SHEETS: 'googlesheets'
});

/**
 * @constant {{
 * MAILCHIMP: String,
 * ACTIVECAMPAIGN: String
 * }}
 */
const RecentEngagementSources = Object.freeze({
	MAILCHIMP: 'mailchimp',
	ACTIVECAMPAIGN: 'activecampaign',
	KLAVIYO: 'klaviyo'
});

/**
 * @constant {{
 * WIX: String,
 * ELEMENTOR_FORMS: String,
 * CONTACT_FORM_7: String,
 * GRAVITY: String,
 * FACEBOOK: String,
 * LINKEDIN: String
 * }}
 */
const FormEngagementSources = Object.freeze({
	WIX: 'wix',
	ELEMENTOR_FORMS: 'elementor',
	CONTACT_FORM_7: 'contact-form-7',
	GRAVITY: 'gravity',
	FACEBOOK: 'facebook',
	LINKEDIN: 'linkedin'
});
/**
 * @constant {String[]}
 */
const WP_ENGAGEMENT_SOURCES = Object.freeze([
	FormEngagementSources.ELEMENTOR_FORMS,
	FormEngagementSources.CONTACT_FORM_7,
	FormEngagementSources.GRAVITY
]);

/**
 * @constant {{
 * CALENDLY: String
 * }}
 */
const MeetingEngagementSources = Object.freeze({
	CALENDLY: 'calendly'
});

/**
 * @constant {{
 * FACEBOOK_ADS: String
 * }}
 */
const AdSources = Object.freeze({
	FACEBOOK_ADS: 'facebook-ads'
});

/**
 * @constant {String[]}
 */
const EngagementSourcesWithDestinationSegmentRestriction = Object.freeze([
	AdSources.FACEBOOK_ADS
]);

/**
 * @constant {{
 * mailchimp: String
 * activecampaign: String,
 * sendinblue: String,
 * hubspot-marketing: String,
 * klaviyo: String,
 * mailerlite: String,
 * constantcontact: String,
 * googlesheets: String,
 * wix: String,
 * elementor: String,
 * contactform7: String,
 * gravity: String,
 * facebook: String,
 * linkedin: String,
 * calendly: String,
 * facebook-ads: String,
 * }}
 */
const EngagementSourceNames = Object.freeze({
	[EmailEngagementSources.MAILCHIMP]: 'Mailchimp',
	[EmailEngagementSources.ACTIVECAMPAIGN]: 'ActiveCampaign',
	[EmailEngagementSources.SENDINBLUE]: 'Brevo',
	[EmailEngagementSources.HUBSPOT_MARKETING]: 'Hubspot Marketing',
	[EmailEngagementSources.KLAVIYO]: 'Klaviyo',
	[EmailEngagementSources.MAILERLITE]: 'MailerLite',
	[EmailEngagementSources.CONSTANT_CONTACT]: 'Constant Contact',
	[EmailEngagementSources.GOOGLE_SHEETS]: 'Google Sheets',
	[FormEngagementSources.WIX]: 'Wix',
	[FormEngagementSources.ELEMENTOR_FORMS]: 'Elementor Forms',
	[FormEngagementSources.CONTACT_FORM_7]: 'Contact Form 7',
	[FormEngagementSources.GRAVITY]: 'Gravity Forms',
	[FormEngagementSources.FACEBOOK]: 'Facebook Lead Forms',
	[FormEngagementSources.LINKEDIN]: 'LinkedIn Lead Gen Forms',
	[MeetingEngagementSources.CALENDLY]: 'Calendly',
	[AdSources.FACEBOOK_ADS]: 'Facebook Custom Audience'
});

/**
 * @constant {{
 * mailchimp: String
 * activecampaign: String
 * }}
 */
const EngagementSourceSegmentNames = Object.freeze({
	[EmailEngagementSources.MAILCHIMP]: 'audience',
	[EmailEngagementSources.ACTIVECAMPAIGN]: 'list',
	[EmailEngagementSources.SENDINBLUE]: 'list',
	[EmailEngagementSources.HUBSPOT_MARKETING]: 'list',
	[EmailEngagementSources.KLAVIYO]: 'list',
	[EmailEngagementSources.GOOGLE_SHEETS]: 'spreadsheet'
});

/**
 * Enum for auth providers.
 * @constant {{
 * EMAIL: String,
 * PIPEDRIVE: String
 * }}
 */
const AuthProviders = Object.freeze({
	EMAIL: 'email',
	PIPEDRIVE: 'pipedrive',
	HUBSPOT: 'hubspot',
	GOOGLE: 'google'
});

/**
 * @constant {{
 * SUBSCRIBED: String,
 * UNSUBSCRIBED: String
 * }}
 */
const ContactSubscriptionStatuses = Object.freeze({
	SUBSCRIBED: 'subscribed',
	UNSUBSCRIBED: 'unsubscribed'
});

/**
 * @constant {{
 * TEXT: String
 * }}
 */
const Apps = Object.freeze({
	PIPEDRIVE: 'pipedrive',
	ZENDESK: 'zendesk',
	SLACK: 'slack',
	ACTIVECAMPAIGN: 'activecampaign',
	MAILCHIMP: 'mailchimp',
	HUBSPOT: 'hubspot',
	COPPER: 'copper',
	SQUARESPACE: 'squarespace',
	WIX: 'wix',
	UNBOUNCE: 'unbounce',
	CALENDLY: 'calendly',
	ZOOM: 'zoom',
	AIRTABLE: 'airtable',
	GOOGLE_ADS: 'google_ads',
	LINKEDIN: 'linkedin',
	WP_FORMS: 'wpforms',
	GRAVITY: 'gravity',
	ELEMENTOR_FORMS: 'elementor',
	CF7: 'contact-form-7',
	FACEBOOK: 'facebook',
	SENDINBLUE: 'sendinblue',
	BREVO: 'brevo',
	SALESFORCE: 'salesforce',
	LINKEDIN_MATCHED_AUDIENCES: 'linkedin_matched_audiences',
	GOOGLE_ADS_CUSTOMER_LIST: 'google_ads_customer_list',
	HUBSPOT_MARKETING: 'hubspot-marketing',
	FACEBOOK_ADS: 'facebook-ads',
	KLAVIYO: 'klaviyo',
	MAILERLITE: 'mailerlite',
	CONSTANT_CONTACT: 'constantcontact',
	GETRESPONSE: 'getresponse',
	SENDGRID: 'sendgrid',
	MONDAY: 'monday',
	GOOGLE_SHEETS: 'googlesheets'
});

/**
 * @constant {{
 * TEXT: String
 * }}
 */
const AppLabels = Object.freeze({
	[Apps.PIPEDRIVE]: 'Pipedrive',
	[Apps.ZENDESK]: 'Zendesk Sell',
	[Apps.SLACK]: 'Slack',
	[Apps.ACTIVECAMPAIGN]: 'ActiveCampaign',
	[Apps.MAILCHIMP]: 'Mailchimp',
	[Apps.HUBSPOT]: 'HubSpot CRM',
	[Apps.COPPER]: 'Copper',
	[Apps.SQUARESPACE]: 'Squarespace Forms',
	[Apps.WIX]: 'Wix forms',
	[Apps.UNBOUNCE]: 'Unbounce',
	[Apps.CALENDLY]: 'Calendly',
	[Apps.ZOOM]: 'Zoom Video Webinars',
	[Apps.AIRTABLE]: 'Airtable',
	[Apps.GOOGLE_ADS]: 'Google Ads Lead Forms',
	[Apps.LINKEDIN]: 'LinkedIn Lead Gen Forms',
	[Apps.WP_FORMS]: 'WP Forms',
	[Apps.GRAVITY]: 'Gravity Forms',
	[Apps.ELEMENTOR_FORMS]: 'Elementor Forms',
	[Apps.CF7]: 'Contact Form 7',
	[Apps.FACEBOOK]: 'Facebook Lead Forms',
	[Apps.FACEBOOK_ADS]: 'Facebook Custom Audiences',
	[Apps.SENDINBLUE]: 'Brevo (formerly Sendinblue)',
	[Apps.SALESFORCE]: 'Salesforce',
	[Apps.LINKEDIN_MATCHED_AUDIENCES]: 'LinkedIn Matched Audiences',
	[Apps.GOOGLE_ADS_CUSTOMER_LIST]: 'Google Ads Customer List',
	[Apps.HUBSPOT_MARKETING]: 'Hubspot Marketing',
	[Apps.KLAVIYO]: 'Klaviyo',
	[Apps.MAILERLITE]: 'MailerLite',
	[Apps.CONSTANT_CONTACT]: 'Constant Contact',
	[Apps.GETRESPONSE]: 'GetResponse',
	[Apps.SENDGRID]: 'Sendgrid',
	[Apps.MONDAY]: 'Monday',
	[Apps.GOOGLE_SHEETS]: 'Google Sheets'
});

/**
 * @constant {{
 * CRM: String,
 * MARKETING: String
 * }}
 */
const AppTypes = Object.freeze({
	CRM: 'CRM',
	MARKETING: 'MARKETING'
});

/**
 * @constant {String[]}
 */
const ConnectorLiteApps = Object.freeze([
	Apps.COPPER,
	Apps.WIX,
	Apps.CF7,
	Apps.ELEMENTOR_FORMS,
	Apps.WP_FORMS,
	Apps.GRAVITY,
	Apps.SQUARESPACE,
	Apps.UNBOUNCE,
	Apps.GOOGLE_ADS,
	Apps.LINKEDIN,
	Apps.CALENDLY,
	Apps.FACEBOOK,
	Apps.FACEBOOK_ADS,
	Apps.KLAVIYO
]);

/**
 * @constant {String[]}
 */
const HistoricalSyncingSources = Object.freeze([
	EmailEngagementSources.MAILCHIMP,
	EmailEngagementSources.ACTIVECAMPAIGN
]);

/**
 * @constant {String}
 */
const NOT_IMPLEMENTED = 'not implemented';

/**
 * @constant {String}
 */
const SUPPORT_BASE_URL = 'https://support.outfunnel.com/en';

/**
 * @constant {Object}
 */
const LearnMoreLinks = Object.freeze({
	commonReasonsDataIsNotSyncing: `${SUPPORT_BASE_URL}/articles/3245428-common-reasons-data-is-not-syncing-as-expected#h_e0c62f2df7`,
	twoWayIntegration: `${SUPPORT_BASE_URL}/articles/4376897-using-the-2-way-hubspot-mailchimp-integration`,
	[ContactSources.PIPEDRIVE]: {
		[EmailEngagementSources.MAILCHIMP]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/5216792-2-way-pipedrive-mailchimp-integration#h_00ab5ef80c`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/5216792-2-way-pipedrive-mailchimp-integration#h_00ab5ef80c`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/5216792-2-way-pipedrive-mailchimp-integration#h_f5f5c8ea97`
		},
		[EmailEngagementSources.ACTIVECAMPAIGN]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/5126662-2-way-pipedrive-activecampaign-connection#h_f918a0540f`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/5126662-2-way-pipedrive-activecampaign-integration#h_3cb93c762c`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/5126662-2-way-pipedrive-activecampaign-connection#h_a8c050771d`
		},
		[EmailEngagementSources.HUBSPOT_MARKETING]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6055943-2-way-pipedrive-hubspot-marketing-integration`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/6055943-2-way-pipedrive-hubspot-marketing-integration`
		},
		[EmailEngagementSources.SENDINBLUE]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6000751-2-way-pipedrive-sendinblue-integration#h_8b80672644`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/6000751-2-way-pipedrive-sendinblue-integration#h_2cb2d00bb1`
		},
		[EmailEngagementSources.KLAVIYO]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/8450445-2-way-pipedrive-klaviyo-integration#h_83a598da05`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/8450445-2-way-pipedrive-klaviyo-integration#h_83a598da05`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/8450445-2-way-pipedrive-klaviyo-integration#h_60bc9507f9`
		},
		[EmailEngagementSources.MAILERLITE]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/articles/9948315-2-way-pipedrive-mailerlite-sync`
		},
		[EmailEngagementSources.CONSTANT_CONTACT]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/10038563-2-way-pipedrive-constantcontact-sync`
		},
		[EmailEngagementSources.GOOGLE_SHEETS]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/10665481-pipedrive-google-sheets-sync`
		},
		[FormEngagementSources.WIX]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5183016-wix-forms-crm-connection`
		},
		[FormEngagementSources.ELEMENTOR_FORMS]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.CONTACT_FORM_7]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.GRAVITY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.FACEBOOK]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5537716-facebook-ads-crm-integration`
		},
		[FormEngagementSources.LINKEDIN]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/7973266-linkedin-lead-gen-forms-crm-connection`
		},
		[MeetingEngagementSources.CALENDLY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5741443-calendly-crm-connection`
		},
		[AdSources.FACEBOOK_ADS]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6358615-crm-facebook-custom-audiences-connection`
		}
	},
	[ContactSources.COPPER]: {
		[EmailEngagementSources.MAILCHIMP]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/3557293-2-way-copper-mailchimp-connection#h_398df60027`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/3557293-2-way-copper-mailchimp-integration#h_398df60027`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/3557293-2-way-copper-mailchimp-connection#h_d4e5730d78`
		},
		[EmailEngagementSources.ACTIVECAMPAIGN]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/5127007-2-way-copper-activecampaign-connection#h_3ff468bde2`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/5127007-2-way-copper-activecampaign-integration#h_4ee03f9c3a`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/5127007-2-way-copper-activecampaign-connection#h_a221dfb223`
		},
		[EmailEngagementSources.HUBSPOT_MARKETING]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6056016-2-way-copper-hubspot-marketing-integration`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/6056016-2-way-copper-hubspot-marketing-integration`
		},
		[EmailEngagementSources.SENDINBLUE]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6007387-2-way-copper-sendinblue-integration#h_6ec4e1add4`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/6007387-2-way-copper-sendinblue-integration#h_8d44b8fe38`
		},
		[EmailEngagementSources.KLAVIYO]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/8459769-2-way-copper-klaviyo-integration#h_6ea7f094ed`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/8459769-2-way-copper-klaviyo-integration#h_6ea7f094ed`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/8459769-2-way-copper-klaviyo-integration#h_cf422ad26c`
		},
		[EmailEngagementSources.MAILERLITE]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/articles/9950885-2-way-copper-mailerlite-sync`
		},
		[EmailEngagementSources.CONSTANT_CONTACT]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/10038580-2-way-copper-constantcontact-sync`
		},
		[EmailEngagementSources.GOOGLE_SHEETS]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/10665910-copper-google-sheets-sync`
		},
		[FormEngagementSources.WIX]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5183016-wix-forms-crm-connection`
		},
		[FormEngagementSources.ELEMENTOR_FORMS]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.CONTACT_FORM_7]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.GRAVITY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.FACEBOOK]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5537716-facebook-ads-crm-integration`
		},
		[FormEngagementSources.LINKEDIN]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/7973266-linkedin-lead-gen-forms-crm-connection`
		},
		[MeetingEngagementSources.CALENDLY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5741443-calendly-crm-connection`
		},
		[AdSources.FACEBOOK_ADS]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6358615-crm-facebook-custom-audiences-connection`
		}
	},
	[ContactSources.HUBSPOT]: {
		[EmailEngagementSources.MAILCHIMP]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/4376897-2-way-hubspot-mailchimp-integration#h_8e424e6cbb`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/4376897-2-way-hubspot-mailchimp-integration#h_8e424e6cbb`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/4376897-2-way-hubspot-mailchimp-integration#h_77cae9c252`
		},
		[EmailEngagementSources.ACTIVECAMPAIGN]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/5046859-2-way-hubspot-activecampaign-connection#h_f671cc5abe`,
			contactCreationRules: `${SUPPORT_BASE_URL}/articles/5046859-2-way-hubspot-activecampaign-integration#h_3357fd2117`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/5046859-2-way-hubspot-activecampaign-connection#h_e1ae366045`
		},
		[EmailEngagementSources.SENDINBLUE]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6004287-2-way-hubspot-sendinblue-integration#h_b442dfb6fe`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/6004287-2-way-hubspot-sendinblue-integration#h_2f1f7262a9`
		},
		[EmailEngagementSources.KLAVIYO]: {
			contactSyncRules: `${SUPPORT_BASE_URL}articles/8459791-2-way-hubspot-klaviyo-integration#h_cd7c6d2926`,
			contactCreationRules: `${SUPPORT_BASE_URL}articles/8459791-2-way-hubspot-klaviyo-integration#h_cd7c6d2926`,
			engagementSync: `${SUPPORT_BASE_URL}/articles/8459791-2-way-hubspot-klaviyo-integration#h_d43b10df6a`
		},
		[EmailEngagementSources.MAILERLITE]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/articles/9948248-2-way-hubspot-mailerlite-sync`
		},
		[EmailEngagementSources.CONSTANT_CONTACT]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/10038571-2-way-hubspot-constantcontact-sync`
		},
		[EmailEngagementSources.GOOGLE_SHEETS]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/10665788-hubspot-google-sheets-sync`
		},
		[FormEngagementSources.WIX]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5183016-wix-forms-crm-connection`
		},
		[FormEngagementSources.ELEMENTOR_FORMS]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.CONTACT_FORM_7]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.GRAVITY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5234038-wordpress-forms-crm-connection`
		},
		[FormEngagementSources.FACEBOOK]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5537716-facebook-ads-crm-integration`
		},
		[FormEngagementSources.LINKEDIN]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/7973266-linkedin-lead-gen-forms-crm-connection`
		},
		[MeetingEngagementSources.CALENDLY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5741443-calendly-crm-connection`
		},
		[AdSources.FACEBOOK_ADS]: {
			contactSyncRules: `${SUPPORT_BASE_URL}/articles/6358615-crm-facebook-custom-audiences-connection`
		}
	},
	[ContactSources.SALESFORCE]: {
		[EmailEngagementSources.MAILCHIMP]: {
			engagementSync: `${SUPPORT_BASE_URL}/en/articles/9614695-2-way-salesforce-mailchimp-integration`
		},
		[EmailEngagementSources.ACTIVECAMPAIGN]: {
			engagementSync: `${SUPPORT_BASE_URL}/en/articles/9614884-2-way-salesforce-activecampaign-integration`
		},
		[EmailEngagementSources.SENDINBLUE]: {
			engagementSync: `${SUPPORT_BASE_URL}/en/articles/9614912-2-way-salesforce-brevo-integration`
		},
		[EmailEngagementSources.KLAVIYO]: {
			engagementSync: `${SUPPORT_BASE_URL}/en/articles/9614850-2-way-salesforce-klaviyo-integration`
		},
		[EmailEngagementSources.HUBSPOT_MARKETING]: {
			engagementSync: `${SUPPORT_BASE_URL}/en/articles/9614928-2-way-salesforce-hubspot-marketing-integration`
		},
		[FormEngagementSources.FACEBOOK]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5537716-facebook-lead-ads-crm-connection`
		},
		[FormEngagementSources.LINKEDIN]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/7973266-linkedin-lead-gen-forms-crm-connection`
		},
		[FormEngagementSources.WIX]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5997712-wordpress-and-wix-forms-salesforce-integration`
		},
		[FormEngagementSources.ELEMENTOR_FORMS]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5997712-wordpress-and-wix-forms-salesforce-integration`
		},
		[FormEngagementSources.CONTACT_FORM_7]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5997712-wordpress-and-wix-forms-salesforce-integration`
		},
		[FormEngagementSources.GRAVITY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5997712-wordpress-and-wix-forms-salesforce-integration`
		},
		[MeetingEngagementSources.CALENDLY]: {
			engagementSync: `${SUPPORT_BASE_URL}/articles/5741443-calendly-crm-connection`
		}
	}
});

/**
 * @constant {Object}
 */
const ContactSyncDirections = Object.freeze({
	TWO_WAY: 'twoWay',
	MARKETING_TO_CRM: 'marketingToCrm',
	CRM_TO_MARKETING: 'crmToMarketing'
});

/**
 * @constant {{
 * TEXT: String,
 * NUMBER: String,
 * ORG: String,
 * NAME: String,
 * SINGLE_CHOICE: String,
 * MULTIPLE_CHOICE: String,
 * PHONE: String,
 * MONETARY: String,
 * TAG: String,
 * DATE: String,
 * DATETIME: String,
 * DATE_WITHOUT_YEAR: String,
 * ADDRESS: String,
 * USER: String,
 * LOCATION: String
 * }}
 */
const FieldTypes = Object.freeze({
	TEXT: 'text',
	NUMBER: 'number',
	ORG: 'org',
	NAME: 'name',
	SINGLE_CHOICE: 'single_choice',
	MULTIPLE_CHOICE: 'multiple_choice',
	PHONE: 'phone',
	MONETARY: 'monetary',
	TAG: 'tag',
	DATE: 'date',
	DATETIME: 'datetime',
	DATE_WITHOUT_YEAR: 'date_without_year',
	ADDRESS: 'address',
	USER: 'user',
	LOCATION: 'location'
});

const SegmentTypes = {
	LIST: 'list',
	SEGMENT: 'segment',
	SYNTHETIC: 'synthetic'
};

const AllContactsSourceSegment = Object.freeze({
	ID: 'all',
	TYPE: 'general',
	VALUE: 1
});

export {
	AllContactsSourceSegment,
	IntlFormatOptions,
	AlertLevels,
	EntityTypes,
	SubscriptionStatuses,
	CampaignStatuses,
	SegmentConditionTypes,
	ConnectionStatuses,
	ContactSyncDirections,
	ContactSources,
	ContactSourceNames,
	ContactSourceSegmentNames,
	CrmSources,
	EmailEngagementSources,
	FormEngagementSources,
	AdSources,
	MeetingEngagementSources,
	EngagementSourceNames,
	EngagementSourceSegmentNames,
	AuthProviders,
	ContactSubscriptionStatuses,
	Apps,
	AppLabels,
	AppTypes,
	CacheControlHeaderValues,
	Headers,
	ConnectorLiteApps,
	HistoricalSyncingSources,
	WP_ENGAGEMENT_SOURCES,
	NOT_IMPLEMENTED,
	Features,
	LearnMoreLinks,
	EngagementSourcesWithDestinationSegmentRestriction,
	SUPPORT_BASE_URL,
	RecentEngagementSources,
	FieldTypes,
	SegmentTypes
};
