/* eslint-disable max-lines */
import { api } from 'api';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import logger from 'utils/logger';
import utils from 'utils';
import {
	AppLabels,
	AppTypes,
	ContactSources,
	EmailEngagementSources,
	FormEngagementSources,
	EngagementSourceNames,
	MeetingEngagementSources,
	AdSources,
	WP_ENGAGEMENT_SOURCES,
	Features
} from 'utils/constants';
import { ConnectionTypes } from 'utils/constants/connections';
import { loadPaperformScript } from 'utils/paperform';
import { EventNames, track } from 'utils/analytics';

import { GET_USER } from 'store/modules/user/actionTypes';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	OAUTH_CONNECT_START,
	WIX_CONNECT_START
} from 'store/modules/account/integrations/actionTypes';

import {
	isTwoWaySyncSupported
} from 'utils/apps';

import { isContactSyncTabVisible } from 'store/modules/moduleConnections/helpers';
import {
	APPS,
	CONNECTION_CONTACT_SOURCE,
	CONNECTION_ENGAGEMENT_SOURCE,
	CONNECTION_DIALOG_RESEARCH_VISIBILITY,
	APP_AUTH_APP_ID,
	APP_AUTH_DIALOG_VISIBILITY,
	APP_AUTH_ACCOUNT_ID,
	APP_AUTH_API_KEY,
	GENERAL_ERRORS,
	APP_AUTH_ERRORS,
	WP_PLUGIN_AUTH_DIALOG_VISIBILITY,
	WP_PLUGIN_AUTH_PLUGIN_NAME,
	WP_PLUGIN_AUTH_ERRORS,
	DIALOG_SUGGEST_APP_VISIBILITY,
	DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY,
	CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY,
	ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY
} from './mutationTypes';

import {
	APPS_GET,
	APP_SELECT,
	APP_PRESELECT,
	CONNECTION_CREATE,
	CONNECTION_CREATE_CONTACT_SYNC,
	CONNECTION_CREATE_ENGAGEMENT_SYNC,
	CONNECTION_DIALOG_RESEARCH_OPEN,
	CONNECTION_DIALOG_RESEARCH_CLOSE,
	CONNECTION_ANALYTICS_TRACK,
	CONTACT_SOURCE_AUTHENTICATE,
	ENGAGEMENT_SOURCE_AUTHENTICATE,
	FEATURES_GET,
	APP_AUTH_DIALOG_SHOW,
	APP_AUTH_DIALOG_HIDE,
	APP_AUTH_DIALOG_SAVE,
	APP_AUTH_DIALOG_RESET,
	WP_PLUGIN_AUTH_DIALOG_CONFIRM,
	WP_PLUGIN_AUTH_DIALOG_SHOW,
	WP_PLUGIN_AUTH_DIALOG_HIDE,
	WP_PLUGIN_AUTH_DIALOG_RESET,
	DIALOG_SUGGEST_APP_SHOW,
	DIALOG_SUGGEST_APP_CLOSE,
	VALUE_COPY,
	DIALOG_HUBSPOT_MKT_CONTACT_SYNC_SHOW,
	DIALOG_HUBSPOT_MKT_CONTACT_SYNC_CLOSE,
	CONTACT_SYNC_LEARN_MORE_DIALOG_OPEN,
	CONTACT_SYNC_LEARN_MORE_DIALOG_CLOSE,
	ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_OPEN,
	ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_CLOSE
} from './actionTypes';

const WP_PLUGIN_AUTH_ERROR = {
	error: 'wp_plugin_auth_failed',
	message: 'Something went wrong. Please verify details. Contact support if the issue persists.',
	context: 'wp_plugin_auth'
};

/**
 * @param {String} appId
 * @return {Boolean}
 */
const isEmailApp = appId => Object.values(EmailEngagementSources).includes(appId);

/**
 * @param {String} appId
 * @return {Boolean}
 */
const isFormApp = appId => Object.values(FormEngagementSources).includes(appId);

/**
 * @param {String} appId
 * @return {Boolean}
 */
const isMeetingApp = appId => Object.values(MeetingEngagementSources).includes(appId);

/**
 * @param {String} appId
 * @return {Boolean}
 */
const isAdApp = appId => Object.values(AdSources).includes(appId);

/**
 * @param {Object} params
 * @param {String} params.appId
 * @return {String|void}
 */
const getConnectionType = ({ appId }) => {
	if (isFormApp(appId) || isMeetingApp(appId)) {
		return ConnectionTypes.FORM_SYNC;
	}

	if (isAdApp(appId)) {
		return ConnectionTypes.CONTACT_SYNC;
	}
};

/**
 * @param {Object} params
 * @param {Object} params.rootGetters
 * @param {Object} params.state
 * @param {String} params.contactSource
 * @param {String} params.contactDestination
 * @return {Boolean}
 */
const isTwoWaySyncSupportedForApps = ({
	rootGetters,
	state,
	contactSource,
	contactDestination
}) => {
	// add two way sync feature flag check here
	if (contactSource === ContactSources.COPPER) {
		return rootGetters['user/isTwoWaySyncEnabledForCopper'] && isTwoWaySyncSupported(contactDestination, state);
	}

	if (contactDestination === ContactSources.MAILERLITE) {
		return rootGetters['user/isTwoWaySyncEnabledForMailerlite'] && isTwoWaySyncSupported(contactDestination, state);
	}

	if (!isTwoWaySyncSupported(contactSource, state)) {
		return false;
	}

	if (!isTwoWaySyncSupported(contactDestination, state)) {
		return false;
	}

	return true;
};

export const actions = {
	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [APPS_GET]({ commit, rootState: { token } }) {
		try {
			const apps = await api.apps.get(token);

			commit(APPS, apps);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 * @param {String} appId
	 */
	async [APP_SELECT]({ commit, state }, appId) {
		try {
			const app = state.apps.find(({ id }) => id === appId);

			if (app.type === AppTypes.MARKETING) {
				commit(CONNECTION_ENGAGEMENT_SOURCE, appId);

				return;
			}

			if (app.type === AppTypes.CRM) {
				commit(CONNECTION_CONTACT_SOURCE, appId);
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [APP_PRESELECT]({ commit, rootGetters }) {
		if (rootGetters['user/isPipedriveConnected']) {
			commit(CONNECTION_CONTACT_SOURCE, ContactSources.PIPEDRIVE);
		}

		if (rootGetters['user/isCopperConnected']) {
			commit(CONNECTION_CONTACT_SOURCE, ContactSources.COPPER);
		}

		if (rootGetters['user/isHubspotCrmConnected']) {
			commit(CONNECTION_CONTACT_SOURCE, ContactSources.HUBSPOT);
		}

		if (rootGetters['user/isSalesforceConnected']) {
			commit(CONNECTION_CONTACT_SOURCE, ContactSources.SALESFORCE);
		}

		if (rootGetters['user/isMondayConnected']) {
			commit(CONNECTION_CONTACT_SOURCE, ContactSources.MONDAY);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CONNECTION_CREATE]({
		commit,
		dispatch,
		state,
		rootState: { token }
	}) {
		const { connectivity } = state.apps.find(app => app.id === state.connectionContactSource);

		const isConnectionCreationForbidden = [
			isEmailApp(state.connectionEngagementSource) && !connectivity.areEmailAppsSupported,
			isFormApp(state.connectionEngagementSource) && !connectivity.areFormAppsSupported,
			isMeetingApp(state.connectionEngagementSource) && !connectivity.areMeetingAppsSupported,
			isAdApp(state.connectionEngagementSource) && !connectivity.areAdAppsSupported
		].some(Boolean);

		if (isConnectionCreationForbidden) {
			return dispatch(CONNECTION_DIALOG_RESEARCH_OPEN);
		}

		const type = getConnectionType({
			appId: state.connectionEngagementSource
		});

		try {
			const connectionData = {
				contactSource: state.connectionContactSource,
				engagementSource: state.connectionEngagementSource,
				type
			};

			const response = await api.connections.post({ token, connectionData });

			if (isContactSyncTabVisible(response)) {
				return router.push({
					name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC,
					params: {
						id: response._id
					}
				});
			}

			return router.push({
				name: ROUTE_NAMES.CONNECTIONS_EDIT_ENGAGEMENT_SYNC,
				params: {
					id: response._id
				}
			});
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CONNECTION_CREATE_CONTACT_SYNC]({
		commit,
		dispatch,
		state,
		rootState: { token },
		rootGetters
	}) {
		const { connectivity } = state.apps.find(app => app.id === state.connectionContactSource);

		const isConnectionCreationForbidden = [
			isEmailApp(state.connectionEngagementSource) && !connectivity.areEmailAppsSupported,
			isFormApp(state.connectionEngagementSource) && !connectivity.areFormAppsSupported,
			isMeetingApp(state.connectionEngagementSource) && !connectivity.areMeetingAppsSupported,
			isAdApp(state.connectionEngagementSource) && !connectivity.areAdAppsSupported
		].some(Boolean);

		if (isConnectionCreationForbidden) {
			return dispatch(CONNECTION_DIALOG_RESEARCH_OPEN);
		}

		try {
			const connectionData = {
				contactSource: state.connectionContactSource,
				engagementSource: state.connectionEngagementSource,
				type: ConnectionTypes.CONTACT_SYNC
			};

			const response = await api.connections.post({ token, connectionData });

			const isTwoWaySupported = isTwoWaySyncSupportedForApps({
				rootGetters,
				state,
				contactSource: state.connectionContactSource,
				contactDestination: state.connectionEngagementSource
			});

			if (isTwoWaySupported) {
				return router.push({
					name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC_SELECT_DIRECTION,
					params: {
						id: response._id
					}
				});
			}

			return router.push({
				name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC,
				params: {
					id: response._id
				}
			});
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CONNECTION_CREATE_ENGAGEMENT_SYNC]({
		commit,
		dispatch,
		state,
		rootState: { token }
	}) {
		const { connectivity } = state.apps.find(app => app.id === state.connectionContactSource);

		const isConnectionCreationForbidden = [
			isEmailApp(state.connectionEngagementSource) && !connectivity.areEmailAppsSupported,
			isFormApp(state.connectionEngagementSource) && !connectivity.areFormAppsSupported,
			isMeetingApp(state.connectionEngagementSource) && !connectivity.areMeetingAppsSupported,
			isAdApp(state.connectionEngagementSource) && !connectivity.areAdAppsSupported
		].some(Boolean);

		if (isConnectionCreationForbidden) {
			return dispatch(CONNECTION_DIALOG_RESEARCH_OPEN);
		}

		try {
			const connectionData = {
				contactSource: state.connectionContactSource,
				engagementSource: state.connectionEngagementSource,
				type: ConnectionTypes.ENGAGEMENT_SYNC
			};

			const response = await api.connections.post({ token, connectionData });

			return router.push({
				name: ROUTE_NAMES.CONNECTIONS_EDIT_ENGAGEMENT_SYNC,
				params: {
					id: response._id
				}
			});
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_DIALOG_RESEARCH_OPEN]({ commit }) {
		commit(CONNECTION_DIALOG_RESEARCH_VISIBILITY, true);
	},

	[CONNECTION_DIALOG_RESEARCH_CLOSE]({ commit }) {
		commit(CONNECTION_DIALOG_RESEARCH_VISIBILITY, false);
	},

	[CONNECTION_ANALYTICS_TRACK]({ state, rootState }) {
		track({
			event: EventNames.CONNECTION_CREATION_INITIATED,
			properties: {
				selectedAppsSlug: `${state.connectionContactSource}:${state.connectionEngagementSource}`,
				email: rootState.user?.profile?.email
			}
		});
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CONTACT_SOURCE_AUTHENTICATE]({ state, dispatch }) {
		if (state.connectionContactSource === ContactSources.COPPER) {
			return dispatch(APP_AUTH_DIALOG_SHOW, ContactSources.COPPER);
		}

		if (state.connectionContactSource === ContactSources.AIRTABLE) {
			return dispatch(APP_AUTH_DIALOG_SHOW, ContactSources.AIRTABLE);
		}

		dispatch(
			`account/integrations/${OAUTH_CONNECT_START}`,
			{ provider: state.connectionContactSource },
			{ root: true }
		);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [ENGAGEMENT_SOURCE_AUTHENTICATE]({ rootGetters, state, dispatch }) {
		if (WP_ENGAGEMENT_SOURCES.includes(state.connectionEngagementSource)) {
			return dispatch(WP_PLUGIN_AUTH_DIALOG_SHOW, state.connectionEngagementSource);
		}

		if (state.connectionEngagementSource === EmailEngagementSources.ACTIVECAMPAIGN) {
			return dispatch(APP_AUTH_DIALOG_SHOW, EmailEngagementSources.ACTIVECAMPAIGN);
		}

		if (state.connectionEngagementSource === EmailEngagementSources.SENDINBLUE) {
			return dispatch(APP_AUTH_DIALOG_SHOW, EmailEngagementSources.SENDINBLUE);
		}

		if (state.connectionEngagementSource === EmailEngagementSources.MAILERLITE) {
			return dispatch(APP_AUTH_DIALOG_SHOW, EmailEngagementSources.MAILERLITE);
		}

		if (state.connectionEngagementSource === EmailEngagementSources.SENDGRID) {
			return dispatch(APP_AUTH_DIALOG_SHOW, EmailEngagementSources.SENDGRID);
		}

		if (state.connectionEngagementSource === FormEngagementSources.WIX) {
			return dispatch(
				`account/integrations/${WIX_CONNECT_START}`,
				null,
				{ root: true }
			);
		}

		if (state.connectionEngagementSource === EmailEngagementSources.KLAVIYO && !rootGetters['user/isKlaviyoOauthEnabled']) {
			return dispatch(APP_AUTH_DIALOG_SHOW, EmailEngagementSources.KLAVIYO);
		}

		dispatch(
			`account/integrations/${OAUTH_CONNECT_START}`,
			{ provider: state.connectionEngagementSource },
			{ root: true }
		);
	},

	[DIALOG_SUGGEST_APP_SHOW]({ commit }) {
		loadPaperformScript();
		commit(DIALOG_SUGGEST_APP_VISIBILITY, true);
	},

	[DIALOG_SUGGEST_APP_CLOSE]({ commit }) {
		commit(DIALOG_SUGGEST_APP_VISIBILITY, false);
	},

	[DIALOG_HUBSPOT_MKT_CONTACT_SYNC_SHOW]({ commit }) {
		loadPaperformScript();
		commit(DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY, true);
	},

	[DIALOG_HUBSPOT_MKT_CONTACT_SYNC_CLOSE]({ commit }) {
		commit(DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[FEATURES_GET]: async ({ dispatch, rootGetters }) => {
		const areFeaturesLoaded = rootGetters['user/areFeaturesLoaded'];

		if (!areFeaturesLoaded) {
			await dispatch('user/FEATURES_GET', null, { root: true });
		}

		return rootGetters['user/isFeatureEnabled'](Features.CONNECTOR);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[APP_AUTH_DIALOG_SHOW]({ commit }, appId) {
		commit(APP_AUTH_APP_ID, appId);
		commit(APP_AUTH_ERRORS, []);
		commit(APP_AUTH_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[APP_AUTH_DIALOG_HIDE]({ commit }) {
		commit(APP_AUTH_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [APP_AUTH_DIALOG_SAVE]({
		dispatch,
		commit,
		state,
		rootState: { token }
	}) {
		try {
			await api.integrations.post(token, state.appAuthAppId, state.appAuthData);

			dispatch(APP_AUTH_DIALOG_HIDE);
			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				`${AppLabels[state.appAuthAppId]} authenticated`,
				{ root: true }
			);

			dispatch(APP_AUTH_DIALOG_RESET);
		} catch (exception) {
			if (exception.errors) {
				return commit(APP_AUTH_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[APP_AUTH_DIALOG_RESET]({
		commit
	}) {
		commit(APP_AUTH_APP_ID, '');
		commit(APP_AUTH_ACCOUNT_ID, '');
		commit(APP_AUTH_API_KEY, '');
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [WP_PLUGIN_AUTH_DIALOG_CONFIRM]({
		dispatch, commit, state, rootState: { token }
	}) {
		try {
			const options = await api.connections.options.get({
				token,
				contactSource: null,
				engagementSource: state.connectionEngagementSource
			});

			if (!options.engagementEventSourceSegments) {
				return commit(WP_PLUGIN_AUTH_ERRORS, [WP_PLUGIN_AUTH_ERROR]);
			}

			dispatch(WP_PLUGIN_AUTH_DIALOG_HIDE);
			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				`${state.wpPluginAuthPluginName} configured`,
				{ root: true }
			);
			dispatch(WP_PLUGIN_AUTH_DIALOG_RESET);
		} catch (exception) {
			if (exception.errors) {
				return commit(WP_PLUGIN_AUTH_ERRORS, [WP_PLUGIN_AUTH_ERROR]);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[WP_PLUGIN_AUTH_DIALOG_SHOW]({ commit }, plugin) {
		commit(WP_PLUGIN_AUTH_PLUGIN_NAME, EngagementSourceNames[plugin]);
		commit(WP_PLUGIN_AUTH_ERRORS, []);
		commit(WP_PLUGIN_AUTH_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[WP_PLUGIN_AUTH_DIALOG_HIDE]({ commit }) {
		commit(WP_PLUGIN_AUTH_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[WP_PLUGIN_AUTH_DIALOG_RESET]({
		commit
	}) {
		commit(WP_PLUGIN_AUTH_PLUGIN_NAME, '');
		commit(WP_PLUGIN_AUTH_ERRORS, []);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[VALUE_COPY]: ({ dispatch }, itemName) => {
		utils.copySelectedText();

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			`${itemName} copied to clipboard`,
			{ root: true }
		);
	},

	[CONTACT_SYNC_LEARN_MORE_DIALOG_OPEN]({ commit }) {
		commit(CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[CONTACT_SYNC_LEARN_MORE_DIALOG_CLOSE]({ commit }) {
		commit(CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_OPEN]({ commit }) {
		commit(ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_CLOSE]({ commit }) {
		commit(ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY, false);
	}
};
